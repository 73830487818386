.card {
    background-color: #ffffff;
    border-radius: 2.2vw;
    width: 100%;
    max-width: calc(70% - 10px);
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex; 
    flex-direction: column; 
    gap: 0.1rem;
    flex: 0 0 calc(70% - 10px);
    margin-top: 2rem;
    justify-content: center;
    margin-left: 4rem;
    transition: .2s;
    color: #000000;
  }
  
  .card h2 {
  
    font-size: 1.2rem;
    font-weight: 700;
    margin-top: 0.3rem;
  }
  
  .card p {
   
    font-size: .9rem;
    font-weight: 400;
    margin-top:0;
  }
  
  
  
  @media (max-width: 768px) {
    .card {
      grid-template-columns: 1fr;
      padding: 10px;
    }
  }
  .card:hover{
    scale: 1.05;
    cursor: pointer;
    border-radius: 10px;
    background-color: #6C74CA;
    color: #FFF;
    align-items: center;
  }
  