.main_conatiner_feedBack {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: small;
  padding: 20px;
  gap: 20px;
}

.switchcomponent {
  width: 85%;
  // border: 1px solid red;
  display: flex;
  flex-direction: row;
  height: 50px;
  justify-content: space-between;
  align-items: center;
  padding: 10px 50px;
  margin-top: 15px;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 10px;
}

.differentcomponents {
  background-color: aliceblue;
  padding: 10px 20px;
  border: 1px solid black;
  border-radius: 5px;
  cursor: pointer;
}
.componentContainer {
  // border: 1px solid red;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

@media screen and (max-width: 470px) {
  .switchcomponent {
    width: 85%;

    flex-direction: column;
    height: auto;
    justify-content: space-between;
    align-items: center;
    padding: 10px 50px;
    margin-top: 15px;
    gap: 5px;
    background-color: #fff;
    border: 1px solid #000;
    border-radius: 10px;
  }
  .differentcomponents {
    width: 70%;
    text-align: center;
  }
}

.progressbarcontainer {
  height: 100%;
  width: 100%;

  z-index: 1000000 !important;
  position: fixed;
  background-color: #5c4a4a69;
  top: 0%;
  left: 0%;
}
