.modalContent{
    display: flex;
    flex-direction: column;
    align-self: center;
    padding-top: 1.5rem;
    font-size: 0.95rem;
    width: 30rem;
    row-gap: 1rem;
    height: 18rem;
    margin-top: 1rem;
    overflow-y: scroll;
    .card{
        display: flex;
        flex-direction: column;
        border : 1px solid #9d9d9d;
        border-radius: 1rem;
        row-gap: 0.5rem;
        margin:0rem 2rem;
        padding: 1rem 0.5rem;
        background-color: white;
        .head{
            font-weight: 600;
        }
        .active{
            color: green;
        }
    }
    .card:hover{
      cursor: pointer;
    }
}
.modalContent::-webkit-scrollbar {
    width: 10px;
  }
   
  .modalContent::-webkit-scrollbar-track {
    background-color: #FFF;
    border-radius: 10px;
    
  }
  .modalContent::-webkit-scrollbar-thumb {
    background-color: #6C74CA;
    border-radius: 10px;
    height: 30px;
  }
 .notAvailable
  {
    padding: 1rem;
    text-align: center;
  }

