.card {
    display: flex;
    flex-direction: column;
    border: 1px solid #9d9d9d;
    border-radius: 1rem;
    row-gap: 0.5rem;
    margin: 0rem 2rem;
    padding: 1rem 0.5rem;
    background-color: white;
    td{
        padding-left: 0.5rem;
    }
    .rows{
        text-align: left;
    }
    .head {
        font-weight: 600;
      }
    .active {
      color: green;
    }
  }
  