.cardContainer{
    background-color: #EFF1F7;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1rem 0rem;
    padding: .1rem 1rem;
    border-radius: 15px;
    z-index: 1111;
    transition: .2s;
    .name{
        margin: 1rem;
    }
    .email{
        margin: 1rem;
    }
    .department{
        margin: 1rem;
    }

}
.cardContainer:hover{
    background-color: #6C74CA;
    cursor: pointer;
    color: #FFF;
    //scale: 1.04;
}