.cards {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 4rem;
    justify-content: center; /* Center horizontally */

    padding: 1rem 3rem;
    .card {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%; 
        max-width: 12rem; 
        height: 10rem;
        border-radius: 1.25rem;
        background: white;
        box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);
        .num {
            color: #6C74CA;
            font-size: 3rem;
            font-weight: 700;
        }
        .heading {
            text-align: center;
            font-size: 1rem;
            font-weight: 600;
        }
    }
}
