.modal {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(2px);
    justify-content: center;
    align-items: center;
    z-index: 1;
    .data {
        display: flex;
        flex-direction: column;
        background-color: #EFF1F7;
        width: 32rem;
        border-radius: 1.3rem;
        border: 1px solid #cccccc;
        .head{
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 1rem;
            color: #333;
            .img{
                height: 96px;
                width: 96px;
                border-radius: 7rem;
            }
            .imgOptional{
                height: 96px;
                width: 96px;
                font-size: 2.5rem;
                font-weight: 600;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #6C74CA;
                border-radius: 100px;
                color: #FFf;
            }
        }
        .heading{
            display: flex;
            justify-content: center;
            font-weight: 600;
            color: #9d9d9d;
            div{
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0.75rem 0.5rem;
                border: 2px solid #cccccc;
                border-radius: 0.7rem;
                width: fit-content;
                height: 1rem;
                margin: 0rem 1rem;
                background-color: white;
                &:hover{
                    cursor: pointer;
                }
                &.activeTab {
                    border: 2px solid #6C74CA;
                    color: #6C74CA;
                }
            }
        }
        .closebtn{
            background-color: white;
            border: 1px solid #cccccc;
            border-radius: 0.5rem;
            padding: 0.8rem;
            width: 10rem;
            margin: 1rem 0rem;
            align-self: center;
            font-weight: 700;
            &:hover{
                background-color: #6C74CA;
                color: white;
                cursor: pointer;
            }
          }   
    }
}