.feed_main_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  background-color: #ffffff;
  border-radius: 10px;
  border: 1px solid #c1c1c1;
  padding: 10px 0px;
}
.feedbackContainer_Card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 100%;
  margin: 10px;
  overflow-y: auto;
  overflow-x: hidden;
}
.container_header {
  height: 40px;
  background-color: #eff1f7;
  width: 94%;
  border: 1px solid #c1c1c1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  cursor: pointer;
}
.container_header:hover {
  background-color: #6c74ca !important;
  color: #ffffff;

  border: 2px solid #c1c1c1;
}
.feedbackContainer_Card_button {
  text-align: center;

  padding: 20px;
}
.card_Container {
  border: 1px solid #c1c1c1;
  width: 95%;
  height: auto;
  background-color: #eff1f7;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .indexCount {
    border: 1px solid #c1c1c1;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0px 10px;
  }
  gap: 5px;
}

.card_content {
  width: 95%;
  height: 100%;
  border-left: 1px solid #c1c1c1;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.card_content_child {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  width: 95%;
  padding: 10px 5px;

  div {
    text-align: left;

    width: 100%;
    .cardHeadings {
      font-size: 1rem;
      font-weight: 500;
      color: black;
    }
    .cardHeadingValues {
      font-size: 0.9rem;
      font-weight: 400;
      margin-left: 10px;
    }
    .cardHeadingValues {
      font-size: 0.9rem;
      font-weight: 400;
      margin-left: 10px;
    }
  }
  .subjectList {
    padding: 0px 8px;
  }
}
.card_container_buttons {
  min-width: 40px;
  height: 150px;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px 0px;
  margin-right: 20px;
  gap: 15px;

  button {
    width: 100%;
    height: 30%;
    border: 1px solid #353636;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
      background-color: #47529a;
      color: #ffffff;
    }
  }
}

// feedbackform
.create_feedback_container {
  height: 500px;
  width: 70%;
  z-index: 5;
  position: absolute;
  background-color: #ffffff;
  top: 15%;
  font-size: 0.9rem;
  border: 1px solid #353636;
  border-radius: 20px;
  p {
    text-align: center;
    font-size: 1.2rem;
    font-weight: bold;
  }
}
.cancel_container {
  display: flex;
  justify-content: end;
  padding: 20px;
}
.delete {
  margin-left: 10px;
  width: 25px;
  height: 25px;
  cursor: pointer;
  &:hover {
    color: #ea4335;
  }
}

.feedbackform {
  display: flex;
  flex-direction: column;
  margin: 20px;
  margin-top: 50px;
  justify-content: space-around;
  align-items: center;
  width: 94%;
  height: 60%;
}
.inputContainer {
  width: 70%;
  display: flex;
  justify-content: space-between;
}
.inputheading {
  width: 40%;
  font-size: 0.9rem;
}
.inputfeild {
  border: 1px solid black;
  width: 40%;
  input {
    width: 100%;
    height: 20px;
  }
  select {
    width: 100%;
    height: 20px;
  }
}

.submitButton {
  width: 60%;
  padding: 20px 30px;
  display: flex;
  justify-content: space-around;
  .cancel {
    padding: 5px 20px;
    border: 1px solid black;
    border-radius: 10px;
    cursor: pointer;
    background-color: #ea4335;
    color: #ffffff;
    &:hover {
      background-color: #b32c20;
    }
  }
  .submit {
    padding: 5px 20px;
    border: 1px solid black;
    border-radius: 10px;
    cursor: pointer;
    background-color: #6c74ca;
    color: #ffffff;
    &:hover {
      background-color: #47529a;
    }
  }
}
.dropdownselector {
  overflow-y: auto;
  overflow-x: auto;
  height: auto;
  max-height: 150px;
  z-index: 20;
  position: fixed;
  top: 50;
  width: 18.5%;
  border: 1px solid black;

  background-color: #ffffff;
  div {
    border: 1px solid black;
    padding: 2px 2px;
  }
  div:hover {
    background-color: #6c74ca;
    color: #ffffff;
    cursor: pointer;
  }
}

.viewDetailsContainer {
  height: 500px;
  width: auto;
  z-index: 20;
  position: absolute;
  background-color: #ffffff;
  top: 15%;
  font-size: 0.9rem;
  left: auto;
  border: 1px solid #353636;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  border-radius: 20px;
  margin: 0px 50px;
  padding: 10px;

  overflow-x: hidden;
  p {
    text-align: center;
    font-size: 1.1rem;
    font-weight: bold;
    color: #070808;
  }
  overflow-y: auto;
  .cancel_container {
    display: flex;
    width: 90%;
    justify-content: end;
    padding: 15px;
  }
  .detailsContainer {
    display: flex;
    flex-direction: column;
    color: #020202;
    font-size: 0.9rem;
    width: 100%;
    padding: 10px 20px;
    .headerDiv {
      width: 70%;
      text-align: left;
      .cardHeadings {
        font-weight: 500;
        color: black;
      }
      .cardHeadingValues {
        font-size: 0.9rem;
        font-weight: 400;
        margin-left: 10px;
      }
    }
  }
}

.tableContainer {
  width: auto;
  font-size: 0.8rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow-x: auto;
  overflow-y: auto;
  margin-top: 15px;
  table {
    width: auto;
    border-collapse: collapse;
    overflow-x: auto;
    overflow-y: auto;
  }

  .tableHeader {
    background-color: #5e5b5b;
    color: #ccc;
  }

  .tableHeaderRow th {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
  }

  .tableBodyRow:hover {
    background-color: #f9f9f9;
  }

  .tableRowCell {
    text-align: left;
    border: 1px solid #ddd;
    padding: 8px;
  }
}

@media screen and (max-width: 950px) {
  .tableContainer {
    font-size: 0.8rem;
    .tableHeaderRow th {
      padding: 2px;
    }

    .tableBodyRow:hover {
      background-color: #f9f9f9;
    }

    .tableRowCell {
      padding: 0px;
    }
  }
}
@media screen and (max-width: 850px) {
  .tableContainer {
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 770px) {
  .tableContainer {
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 570px) {
  .tableContainer {
    font-size: 0.7rem;
  }
}
@media screen and (max-width: 500px) {
  .tableContainer {
    font-size: 0.6rem;
  }
}

.rButton {
  padding: 3px 5px;
  border: 1px solid #353636;
  cursor: pointer;
  border-radius: 10px;
  &:hover {
    background-color: #47529a;
    color: #ddd;
  }
}

.nofeedbackContainer {
  font-size: 0.9rem;
  font-weight: bold;
}
