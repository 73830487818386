.groupMemberList{
    .details {
        display: flex;
        justify-content: center;
        max-height: calc(100vh - 100px);
        margin-top: 1rem;
        .list {
          display: flex;
          flex-direction: column;
          width: calc(100% - 4rem);
          margin-bottom: 2rem;
          .head {
            display: flex;
            justify-content: space-between;
            border-radius: 1.9rem 1.9rem 0px 0px;
            min-width: 768px;
            padding: 1rem;
            font-size: 0.9rem;
            font-weight: 600;
            border: 1px solid #cccccc;
            background-color: white;
            .email {
              width: 40%;
            }
            .name {
              width: 40%;
              padding-left: 2rem;
            }
            .phone {
              width: 20%;
            }
          }
          .table{
            overflow-y: scroll;
            border-radius: 0rem 0rem 1.9rem 1.9rem;
            border: 1px solid #cccccc;
            border-top: 0;
          .row {
            display: flex;
            min-width: 768px;
            padding: 1rem;
            justify-content: space-between;
            border: 1px solid #cccccc;
            border-left: 0;
            border-right: 0;
            font-size: 0.9rem;
            background-color: white;
            border-top: 0px;
            .email {
              width: 40%;
            }
            .name {
              width: 40%;
            }
            .phone {
              width: 20%;
            }
            &:last-child {
              border-bottom: 0;
            }
            &:hover {
              background-color: #eff1f7;
              cursor: pointer;
            }
          }
        }
      }
    }
}