.searchbarContainer{
    background-color: #FFFFFF;
    padding: .6rem 3.5rem .6rem 3.5rem;
    display: flex;
    justify-content:space-between;
    border-left: 1px solid #c1c1c1;
    .searchForm {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40%;
        .searchInput {
        box-shadow: 0px 1px #0000002c;
          width: 100%;
          min-width: 100px;
          background-color: #EFF1F7;
          height: 20px;
          padding: .6rem .6rem .6rem 2.5rem;
          font-size: 1rem;
          border-radius: 20px;
          border: none;
          outline: none;
          background-image: url("../../assets/search.svg");
          background-repeat: no-repeat;
          background-position: 10px center;
        }
    }
    .profileContainer{
        width: fit-content;
        justify-content: center;
        align-items: center;
        img{
            height: 56px;
            width: 56px;
            border: none;
            border-radius: 100px;
        }
    }
}
.searchresult{
    background-color: #FFFFFF;
    padding: 1rem;
    border-top: 1px solid #c1c1c1;
}