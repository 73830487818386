.loginContainer{
    height: fit-content;
    width: 30rem;
    background-color: #FFFFFF;
    border-radius: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 2rem;
    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.25);

    .loginText{
        padding: .7rem 0rem;
        border-radius: 40px 40px 0 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #6C74CA;
        color: #FFFFFF;
        font-size: 20px;
        font-weight: 600;
    }
    .loginContent{
        margin: 2rem 0rem 2rem 0;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        p{
            font-size: calc(2rem + .5vw);
            font-weight: 700;
            margin: .5rem 0 1.8rem 0;
            color: #333333;
        }
        img{
            height: 94px;
            width: 94px;
        }
        .GoogleLogin{
            margin: 0 1rem;
            margin-bottom: 2px;
        }
       
    }
}
.loginUnsucess{
    font-size: 1rem;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
}