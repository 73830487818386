.modalContent{
    display: flex;
    flex-direction: column;
    align-self: center;
    padding-top: 1.5rem;
    font-size: 0.95rem;
    width: 30rem;
    row-gap: 1rem;
    height: 18rem;
    margin-top: 1rem;
    overflow-y: scroll;
}
.notAvailable
  {
    padding: 1rem;
    text-align: center;
  }