.analyticsConatiner{
    border: 1px solid rgb(0, 0, 0);
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;

}
.headerContentImageContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  img{
    width: 120px ;
    height: 120px;
  }
}
.downloadpdfButton{
  width: 20%;
  cursor: pointer;
   &:hover{
    background-color: #6c74ca;
    color: #fff;

   }
}

.inputContainer {
    width: 40%;
    display: flex;
  justify-content: space-between !important;
  align-items: center;

  
  }
  .inputheading {
    width: 40%;
    font-size: 0.9rem;
  }
  .inputfeild {
    // border: 1px solid black;
    width: 70%;
    input {
      width: 90%;
      height: 20px;
    }
  }
  .dropdownselector {
    overflow-y: auto;
    overflow-x: auto;
    height: auto;
    max-height: 150px;
    z-index: 20;
    position: fixed;
    top: 50;
    width: 18.5%;
    border: 1px solid black;
  
    background-color: #ffffff;
    div {
      border: 1px solid black;
      padding: 2px 2px;
    }
    div:hover {
      background-color: #6c74ca;
      color: #ffffff;
      cursor: pointer;
    }
  }
  
.filterComponent{
    margin: 20px 0px;
    width: 95%;
    height: 60px;
    background-color: #fff;
    border: 1px solid black;
    border-radius: 5px;
    
    background-color: #ffffff;
    padding: 5px 10px;
    display: flex;
    gap: 5px;

}
.analyticsComponent{
    height: auto;
    margin: 20px 0px;
    padding-top: 50px;
    // border:1px solid rgb(59, 58, 58);
    width: 97%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    gap: 50px;
    font-size: 0.7rem;

    
    
}
.feedbackDetailsContainer{
  width: 90%;
  font-size: 1rem;
  font-weight: 400;

}
.headerContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 200px 20px;
    gap: 20px;

}
.headerContent{
    text-align: center;
    font-size: 1.2rem;
    font-weight: 500;
}
.headerContent1{
  
  text-align: center;
  font-size: 1.3rem;
  font-weight: 700;
}

.feedbackGeneratorContainer{
    width: 95%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px 0px;
    

}
.roleInput{
      
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  width: 40%;
  font-size: 0.5rem;
  

  span{
    width: 40%;
    font-size: 0.9rem;
    
  }

  input , select{
    height: 25px;
    width:70%;

    

  }



  }

.classDetailsContainer{
  margin-top: 30px;
  border-bottom: 2px solid black;
  
  page-break-inside: avoid;
  div{
    
  page-break-inside: avoid;
  }
}
.feedbackDetails{
  
  font-size: 0.8rem;
  font-weight: 500;
  span{
    margin-left: 5px;
  }
}
.feedbackDetails1{
  text-align: center;
  font-size:1rem;
  font-weight: 700;
  margin-bottom: 10px;
  span{
    margin-left: 5px;
  }
}
.chartContainer{
  width: 100%;
  display: flex;
  justify-content: center;


}


@media screen and (max-width: 950px) {
  .roleInput{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    span{
      text-align: center;
      width: 100%;
      font-size: 0.9rem;
      
    }
  
    input , select{
      height: 25px;
      width:80%;
  
      
  
    }

  }

  .inputContainer{
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .inputheading{
    width: 100%;
    text-align: center;
  }

}


@media screen and (max-width: 475px) {
  .filterComponent{
    flex-direction: column;
    height: auto;
    justify-content: center;
    align-items: center;
    gap: 10px;

  }
  .inputContainer{
    flex-direction: row;
    width: 100%;

  }
  .roleInput{
    flex-direction: row;
    
    width: 100%;
  }
  .downloadpdfButton{
    width: 80%;
    padding: 5px 10px;
  }
}

