.grpNoticeSync{

margin-top: 1rem;
position: absolute;
z-index: 99;
padding: 5px;
height: 50px;
width: 50px;
background-color: #fff;
top:80% ;
left: 90%;
display: flex;
align-items: center;
justify-content: center;
width: fit-content;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
border-radius: 50%;
cursor: pointer;

span{
display: none;
}
.Cloudicon{
font-size: 2rem;
color: #6C74CA;
margin: 10px;
}
&:hover{

height: auto;
color: #FFF;
background-color: #6C74CA;
left: 80%;
width: 20%;
align-items: center;
justify-content: center;
transform: translateX(-20%);
scale: 1.008;
border-radius: 10px;

span{
display: flex;
width: 70%;
}
.Cloudicon{
color: #FFF;
margin: 0;
}


}
}
.groupdata{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
    column-gap: 10rem;
    row-gap: 5rem;
    padding:  0 2rem;
    padding-top: 2rem;
    height: calc(100vh - 100px);
    overflow-y: scroll;

    .link{
      text-decoration: none;
    }
}
.groupdata::-webkit-scrollbar {
    width: 10px;
  }
   
  .groupdata::-webkit-scrollbar-track {
    background-color: #FFF;
    //border: 1px solid #cccccc;
    border-radius: 10px;
    
  }
  .groupdata::-webkit-scrollbar-thumb {
    background-color: #6C74CA;
    border-radius: 10px;
    height: 100px;
  }
